// core
import React, { useState } from 'react'
// components
import { Expandable, Icon, IDefaultProps, Text } from 'components'
// libraries
import cx from 'classnames'

export interface IFAQs {
  question: string
  answer: string
}

export interface IFAQsProps extends IDefaultProps {
  className?: string
  /**
   * Classname for title color
   */
  classNameHeading?: string
  /**
   * Classname for Plus/Minus Icon
   */
  classNameIcon?: string
  /**
   * Faq(questions and answers) to show
   */
  faq?: IFAQs[]
}

const DEFAULT_FAQs = [
  {
    question: 'What is microblading?',
    answer: `Microblading is a semi-permanent / cosmetic tattoo procedure that involves using a manual hand-held tool to create fine, hair-like strokes in the eyebrow area. These strokes are filled with pigment, giving the appearance of fuller, well-defined eyebrows.`,
  },
  {
    question: 'How long does microblading last?',
    answer: `Microblading typically lasts between 1 to 3 years, depending on factors such as your skin type, lifestyle, and aftercare. Touch-up sessions are recommended every 6 to 12 months to maintain the desired look.`,
  },
  {
    question: 'Does microblading hurt? Is microblading painful?',
    answer: `While pain tolerance varies, most people describe the sensation as mild discomfort rather than outright pain. Topical numbing cream is applied during the procedure to minimize any potential discomfort.`,
  },
]

export const FaqDefault = ({
  className,
  classNameHeading,
  classNameIcon,
  faq = DEFAULT_FAQs,
}: IFAQsProps) => {
  return (
    <section
      className={cx('max-w-5xl mx-auto p-2 mb-8 xxs:p-3 xs:p-4 lg:px-16 lg:my-8', className)}>
      <Text.Heading
        className={cx('text-black text-xl text-center lg:text-5xl', classNameHeading)}
        content="FREQUENTLY ASKED QUESTIONS"
      />

      <Text.Paragraph 
        className='text-center text-lg my-4 lg:!text-3xl lg:my-8 '
        content="Here are some helpful tips"
      />


      {faq.map((data, index) => (
        <FAQ
          key={`${index}`}
          answer={data.answer}
          classNameIcon={classNameIcon}
          question={data.question}
        />
      ))}
    </section>
  )
}

export interface IFAQProps {
  question: string
  answer: string
  classNameAnswerFAQ?: string
  classNameIcon?: string
  classNameQuestionFAQ?: string
}

const FAQ = ({ question, answer, classNameIcon }: IFAQProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Expandable
      className="border-b border-black"
      open={isOpen}
      trigger={
        <div className="flex justify-between p-4 transition-all !bg-opacity-20 ">
          <Text.Heading className="!text-xl text-left lg:text-2xl" content={question} />

          <Icon
            className={cx('text-black ml-5', classNameIcon)}
            name={isOpen ? 'minus' : 'plus'}
          />
        </div>
      }
      onClosing={() => setIsOpen(false)}
      onOpening={() => setIsOpen(true)}>
      <div className="w-full flex flex-col border-b border-black pb-4 px-4">
        <Text.Paragraph useMD className="text-base text-left" content={answer} />
      </div>
    </Expandable>
  )
}
