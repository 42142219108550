// core
import React from 'react'
// components
import { FaqDefault, IFAQsProps } from './variants/FaqDefault'

export const Faq = (props: IFAQsProps) => {
    return(
      <FaqDefault {...props} />
    ) 
}
