// core
import React from 'react'
// components
import { ButtonDefault, IButtonDefaultProps } from './ButtonDefault'
// libraries
import cx from 'classnames'
import { overrideTailwindClasses as two } from 'tailwind-override'

interface IButtonPrimaryProps extends IButtonDefaultProps {}

export const ButtonPrimary = ({ className, color = 'primary', ...otherProps }: IButtonPrimaryProps) => (
  <ButtonDefault
    className={two(
      cx(
        'justify-center bg-black font-bold text-white tracking-widest rounded-0 hover:!bg-primary hover:!text-black hover:!border-black hover:scale-[1.05] lg:text-xl lg:py-5 lg:px-12',
        className
      )
    )}
    color={color}
    {...otherProps}
  />
)
