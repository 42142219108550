//core
import React from 'react';
import App from './App';
//libraries
import { BrowserRouter as Router } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
// styles
import './index.css'
import './styles/animation.scss'
import 'tailwindcss/tailwind.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

function AppSetup() {
  return (
    <Router>
      <App />
    </Router>
  )
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<AppSetup />)