// core
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ViewportContext } from 'App'
// components
import { Expandable, Icon, LinkIcon, Text } from 'components'
import Dropdown from './Dropdown'
// libraries
import { Link } from 'react-router-dom'
// modules
import { ERoutes } from 'modules/navigation'
import { EWindowWidth } from 'utils'
// utils
import { services } from 'utils/config'

export const Header = () => {
  //==================== Context ====================
  const { windowWidth } = useContext(ViewportContext)

  //================== States =======================
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  //================== Events ====================
  const onToggleMobileMenu = () => setIsMobileMenuOpen((prev) => !prev)
  const onCloseMobileMenu = () => setIsMobileMenuOpen(false)

  const onMouseEnter = () => {
    if (windowWidth < EWindowWidth.lg) {
      setDropdown(false)
    } else {
      setDropdown(true)
    }
  }

  const onMouseLeave = () => {
    if (windowWidth < EWindowWidth.lg) {
      setDropdown(false)
    } else {
      setDropdown(false)
    }
  }

  return (
    <nav className="absolute w-full h-20 lg:z-5">
      <div className="max-w-7xl flex items-center font-josefin mx-auto">
        <div
          className="absolute top-0 right-0 translate-x-[-100%] translate-y-[60%] cursor-pointer z-5 lg:hidden"
          onClick={onToggleMobileMenu}>
          <Icon className="text-txt-neon" name={isMobileMenuOpen ? 'times' : 'bars'} size="2x" type="solid" />
        </div>

        <div className="absolute w-24 top-5 left-7 lg:left-[16%] z-5 lg:hidden">
          <Link
            className="uppercase font-bold text-txt-neon no-underline mx-auto px-4"
            to={ERoutes.HOME}
            onClick={onCloseMobileMenu}>
            Neon Beauty
          </Link>
        </div>

        <ul
          className={`absolute top-[76px] flex flex-col space-y-4 w-full h-[90vh] px-4 transition-all duration-500 ease-in-out opacity-100
                      lg:flex-row lg:space-y-0 lg:top-6 lg:items-start lg:list-none
                      ${
                        isMobileMenuOpen &&
                        'bg-txt/95 left-0 opacity-100 z-5 transition-all duration-500 ease-in-out'
                      }`}>
          <li className="flex items-center py-0 lg:h-10 lg:border-b-0">
            <Link
              className="text-txt-neon no-underline text-[24px] mx-auto px-2 hover:border-b-2 hover:border-txt-neon lg:after:content-['|'] lg:after:ml-3"
              to={ERoutes.HOME}
              onClick={onCloseMobileMenu}>
              HOME
            </Link>
          </li>

          {windowWidth > EWindowWidth.lg ? (
            <li
              className="flex items-center lg:h-10 lg:border-b-0"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}>
              <Link
                className="text-txt-neon no-underline text-[24px] mx-auto px-2 hover:border-b-2 hover:border-txt-neon lg:after:content-['|'] lg:after:ml-3"
                to={ERoutes.LIP_NEUTRALIZATION}
                onClick={onCloseMobileMenu}>
                SERVICES <Icon name="caret-down" type="solid" />
              </Link>
              {dropdown && <Dropdown />}
            </li>
          ) : (
            <Expandable
              open={isOpen}
              trigger={
                <div className="flex justify-center items-center space-x-2 transition-all">
                  <Text.Paragraph className="font-bold text-txt-neon !text-[24px]" content="SERVICES" />

                  <Icon className="text-txt-neon" name={isOpen ? 'caret-up' : 'caret-down'} type="solid" />
                </div>
              }
              onClosing={() => setIsOpen(false)}
              onOpening={() => setIsOpen(true)}>
              <div className="w-full flex flex-col justify-center items-center border-b-2 border-black pb-4 px-4">
                {services.map((item, index) => {
                  return (
                    <li key={`services_${index}`}>
                      <Link
                        className="block text-txt-neon no-underline p-4"
                        to={item.path}
                        onClick={onCloseMobileMenu}>
                        {item.title}
                      </Link>
                    </li>
                  )
                })}
              </div>
            </Expandable>
          )}

          <li className="flex items-center lg:h-10 lg:border-b-0">
            <Link
              className="text-txt-neon text-[24px] no-underline mx-auto px-2 hover:border-b-2 hover:border-txt-neon lg:after:content-['|'] lg:after:ml-3"
              to={ERoutes.ABOUT}
              onClick={onCloseMobileMenu}>
              ABOUT
            </Link>
          </li>

          <li className="flex items-center lg:h-10 lg:border-b-0">
            <Link
              className="text-txt-neon text-[24px] no-underline mx-auto px-2 hover:border-b-2 hover:border-txt-neon lg:after:content-['|'] lg:after:ml-3"
              to={ERoutes.CONTACT}
              onClick={onCloseMobileMenu}>
              CONTACT
            </Link>
          </li>

          <li className="flex items-center lg:h-10 lg:border-none">
            <Link
              className="text-txt-neon text-[24px] no-underline mx-auto px-2 hover:border-b-2 hover:border-txt-neon"
              to={ERoutes.BOOK}
              onClick={onCloseMobileMenu}>
              BOOK NOW
            </Link>
          </li>

          <li className="flex space-x-6 h-14 lg:hidden mt-10 mx-auto">
            <LinkIcon
              className="text-primary"
              name="instagram"
              size="2x"
              type="brands"
              url={ERoutes.INSTAGRAM}
            />

            <LinkIcon
              className="text-primary"
              name="facebook-f"
              size="2x"
              type="brands"
              url={ERoutes.FACEBOOK}
            />
          </li>
        </ul>

        <div className="hidden lg:flex lg:space-x-3 lg:absolute lg:top-5 lg:right-[16%]">
          <LinkIcon
            className="text-primary"
            name="instagram"
            size="2x"
            type="brands"
            url={ERoutes.INSTAGRAM}
          />

          <LinkIcon
            className="text-primary"
            name="facebook-f"
            size="2x"
            type="brands"
            url={ERoutes.FACEBOOK}
          />
        </div>
      </div>
    </nav>
  )
}
