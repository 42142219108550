// core
import React, {
    useEffect,
    useState,
  } from 'react'
  import { EWindowWidth, IViewport, TBreakpoints } from 'utils'

// ==============================
//          R E S I Z E
// ==============================
/**
 * A custom hook for getting width of window intime on resize
 * @returns windowWidth as number
 * @usage hook can be used by comparing returned windowWidth with breakpoints from 'EWindowWidth'
 */
export function useWindowWidth(): IViewport {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  
    useEffect(() => {
      function onResize() {
        setWindowWidth(window.innerWidth)
      }
  
      window.addEventListener('resize', onResize)
  
      // initial window update
      onResize()
  
      return () => window.removeEventListener('resize', onResize)
    }, [])
  
    const breakpointNames = Object.keys(EWindowWidth).filter((key) =>
      isNaN(key as any)
    ) as TBreakpoints[]
  
    return {
      windowWidth,
      isLargerThan: breakpointNames.reduce(
        (arr, val) => ({
          ...arr,
          [val]: windowWidth > EWindowWidth[val],
        }),
        {}
      ) as IViewport['isLargerThan'],
      isSmallerThan: breakpointNames.reduce(
        (arr, val) => ({
          ...arr,
          [val]: windowWidth < EWindowWidth[val],
        }),
        {}
      ) as IViewport['isSmallerThan'],
    }
  }
  