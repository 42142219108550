// core
import React from 'react'
// components
import { LinkDefault, LinkIcon, Text } from 'components'
// modules
import { ERoutes } from 'modules/navigation'
import { Link } from 'react-router-dom'
// Utils
import { services } from 'utils/config'

export const Footer = () => {
  return (
    <footer className="relative w-full bg-secondary border-t-2 border-black py-10 px-4 md:py-20">
      <div className="max-w-7xl flex flex-col justify-between space-x-0 space-y-10 mx-auto sm:flex-row sm:space-x-4">
        <div>
          <Text.Heading content="EXPLORE" />

          <div className="flex space-x-4 mt-4 md:space-x-16 md:mt-12">
            <div className="flex flex-col space-y-2">
              <Link to={ERoutes.HOME}> HOME</Link>

              <Link to={ERoutes.LIP_NEUTRALIZATION}> SERVICES</Link>

              <Link to={ERoutes.ABOUT}> ABOUT</Link>

              <Link to={ERoutes.CONTACT}> CONTACT</Link>
            </div>

            <div className="flex flex-col space-y-2">
            { services.map((item, index) => (
              <Link
                key={`services_${index}`}
                className='uppercase'
                to={item.path}
              >
              {item.title}
              </Link>
            ))}
            </div>
          </div>

          <div className="mt-10 md:hidden">
            <Text.Heading className="mt-4" content="LOCATION" size={4} />

            <Text.Paragraph className="mt-5" content="Palmdale Dr," />

            <Text.Paragraph className="mt-2" content="Scarborough, ON M1T 1P2" />
          </div>
        </div>

        <div className="flex flex-col space-y-10 md:space-x-4 md:space-y-0 md:flex-row lg:space-x-36">
          <div>
            <Text.Heading content="HOURS" size={4} />

            <Text.Paragraph className="mt-5" content="By Appointment Only" />

            <div className="hidden md:block">
              <Text.Heading className="mt-11" content="LOCATION" size={4} />

              <Text.Paragraph className="mt-5" content="Palmdale Dr," />

              <Text.Paragraph className="mt-2" content="Scarborough, ON M1T 1P2" />
            </div>
          </div>

          <div>
            <Text.Heading content="CONTACT" size={4} />

            <Text.Paragraph className="mt-5" content="SMS or call us at (647) 949-3625" />

            <Text.Paragraph className="mt-2" content="DM our instagram @ neon beauty lounge" />

            <div className="flex space-x-3 mt-2">
              <LinkIcon name="instagram" size="2x" type="brands" url={ERoutes.INSTAGRAM} />

              <LinkIcon name="facebook-f" size="2x" type="brands" url={ERoutes.FACEBOOK} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
