// core
import React, { lazy, memo } from 'react'
// libraries
import { Route, Routes } from 'react-router-dom'
// routes
import { ERoutes } from './index'
// Defaults
const AboutPage = lazy(async () => import('pages/AboutPage/AboutPage'))
const BookPage = lazy(async () => import('pages/BookPage/BookPage'))
const CarePage = lazy(async () => import('pages/CarePage/CarePage'))
const ContactPage = lazy(async () => import('pages/ContactPage/ContactPage'))
const HomePage = lazy(async () => import('pages/HomePage/HomePage'))
const PoliciesPage = lazy(async () => import('pages/PoliciesPage/PoliciesPage'))
const ServicePage = lazy(async () => import('pages/ServicePage/ServicePage'))

export const Router = memo(function Router() {
  return (
    <Routes>
      <Route element={<AboutPage />} path={ERoutes.ABOUT} />

      <Route element={<CarePage />} path={ERoutes.CARE} />

      <Route element={<ContactPage />} path={ERoutes.CONTACT} />

      <Route element={<ServicePage />} path={ERoutes.SERVICES} />

      <Route element={<HomePage />} path={ERoutes.HOME} />

      <Route element={<BookPage />} path={ERoutes.BOOK} />

      <Route element={<PoliciesPage />} path={ERoutes.POLICIES} />
    </Routes>
  )
})
