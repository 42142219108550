// modules
import { ERoutes } from 'modules/navigation'

export type TService =
  | 'lip-neutralization'
  | 'lip-blushing'
  | 'ageless-liprenew'
  | 'brow-lamination'
  | 'eyebrow-shaping'
  | 'powder-brows'
  | 'microblading'

export const services = [
  {
    title: 'LIP NEUTRALIZATION',
    path: ERoutes.LIP_NEUTRALIZATION,
  },
  {
    title: 'LIP BLUSHING',
    path: ERoutes.LIP_BLUSHING,
  },
  {
    title: 'AGELESS LIPRENEW',
    path: ERoutes.AGELESS_LIPRENEW,
  },
  {
    title: 'BROW LAMINATION',
    path: ERoutes.BROW_LAMINATION,
  },
  {
    title: 'EYEBROW SHAPING',
    path: ERoutes.EYEBROW_SHAPING,
  },
  {
    title: 'POWDER BROWS',
    path: ERoutes.POWDER_BROW,
  },
  {
    title: 'MICROBLADING',
    path: ERoutes.MICROBLADING,
  },
]

export type TCare = 'lip-blush' | 'powder-brows'
