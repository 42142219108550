// core
import React, { createContext } from 'react'
// components
import { Footer, Header } from 'components'
//modules
import { Router } from 'modules/navigation/index'
// utils
import { IViewport, useWindowWidth } from 'utils'

// ==================== Viewport context ====================
export const ViewportContext = createContext<IViewport>({
  windowWidth: 0,
  isSmallerThan: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  },
  isLargerThan: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  },
})

export default function App() {
  // ==================== Hooks ====================
  const viewportValues = useWindowWidth()
  return (
    <ViewportContext.Provider value={viewportValues}>
      <div className="w-full h-full flex overflow-auto">
        {/** SCROLLABLE PAGE CONTENTS */}
        <div
          className="relative w-full h-full flex flex-col max-w-full min-h-screen overflow-auto"
          // #NOTE: Needed for ScrollInAnimation.scrollableParentSelector
          id="pageContent">
          <Header />

          <React.Suspense>
            <div className="relative w-full h-full flex flex-col flex-grow items-center">
              <Router />
            </div>
          </React.Suspense>

          <Footer />
        </div>
      </div>
    </ViewportContext.Provider>
  )
}
