/** Collection of project-specific routes */
export enum ERoutes {
    // Defaults
    HOME = '/',
    NOT_FOUND_PAGE = '*',
    // ============= Internal ==============
    ABOUT = '/about',
    CONTACT = '/contact',
    SERVICES = '/services/:service',
    LIP_NEUTRALIZATION = '/services/lip-neutralization',
    LIP_BLUSHING = '/services/lip-blushing',
    AGELESS_LIPRENEW = '/services/ageless-liprenew',
    BROW_LAMINATION = '/services/brow-lamination',
    EYEBROW_SHAPING = '/services/eyebrow-shaping',
    POWDER_BROW = '/services/powder-brows',
    MICROBLADING = '/services/microblading',
    BOOK = '/book',
    POLICIES = '/policies',
    CARE = '/care/:care',
    PRECARE = '/care/lip-blush',
    AFTERCARE = '/care/powder-brows',

    // Other
    FACEBOOK = 'https://www.facebook.com/neonblounge?mibextid=LQQJ4d',
    INSTAGRAM = 'https://instagram.com/neonblounge?igshid=MzRlODBiNWFlZA=='

}

/** Custom react-router component wrapper */
export * from './Router'

/** Custom react-router `useNavigation()` hook-wrapper */
export * from './useRouter'
