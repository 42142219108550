// core
import React from 'react'
// components
import { ILoaderDefaultProps, LoaderDefault } from './variants/LoaderDefault'
import { LoaderFullScreen } from './variants/LoaderFullScreen'
import { LoaderWrapper } from './variants/LoaderWrapper'

export class Loader extends React.Component<ILoaderDefaultProps> {
  static Default = LoaderDefault
  static Fullscreen = LoaderFullScreen
  static Wrapper = LoaderWrapper

  render() {
    return <LoaderDefault {...this.props} />
  }
}
