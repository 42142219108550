// core
import React, { useState } from 'react'
// libraries
import { Link } from 'react-router-dom'
// utils
import { services } from 'utils/config'

export default function Dropdown() {
  //==================States====================
  const [click, setClick] = useState(false)

  //==================Events====================
  const onClick = () => setClick((prev) => !prev)

  return (
    <>
      <ul
        className={`${click ? 'hidden' : 'absolute top-10 w-52 bg-txt/80 list-none text-start z-10'}`}
        onClick={onClick}>
        {services.map((item, index) => {
          return (
            <li key={`services_${index}`}>
              <Link
                className="block w-full h-full text-txt-neon no-underline p-4 cursor-pointer hover:bg-txt/80 hover:border-b-2 hover:border-txt-neon"
                to={item.path}
                onClick={() => setClick(false)}>
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}
